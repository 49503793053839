<template>
  <v-container class="mt-0 pt-0">
    <v-row>
      <v-col cols="12" class="pt-0">
        <v-card outlined>
          <v-card-title>
            <h3>Novo Fornecedor</h3>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              color="primary"
              :to="{ name: 'provider-list' }"
              exact
            >
              Voltar
            </v-btn>
          </v-card-title>
          <v-row>
            <v-card-text>
              <v-col cols="12">
                <v-form v-model="valid" ref="form" lazy-validation>
                  <v-text-field
                    v-model="provider_form.name"
                    label="Nome"
                    :rules="nameRules"
                    outlined
                    dense
                    required
                    name="provider-name"
                  >
                  </v-text-field>
                  <v-text-field
                    v-model="provider_form.email"
                    label="E-mail destinatário"
                    :rules="emailRules"
                    outlined
                    dense
                    required
                    name="provider-email"
                  >
                  </v-text-field>
                  <v-autocomplete
                    v-model="provider_form.operadora_id_join"
                    :items="operadoras_join.data"
                    item-text="nome_operadora"
                    item-value="id_operadora"
                    outlined
                    dense
                    label="Operadora Join"
                    :loading="loading"
                    clearable
                  ></v-autocomplete>

                  <SolicitationTypeTagsComponent
                      @save="saveTag"
                      :required="false"
                  ></SolicitationTypeTagsComponent>
                </v-form>
              </v-col>
              <v-col cols="12" align="right">
                <v-btn
                  color="primary"
                  @click="saveProviderItem"
                  :loading="creating"
                  :disabled="creating"
                >
                  Salvar
                </v-btn>
              </v-col>
            </v-card-text>
          </v-row>
        </v-card>
      </v-col>

      <div>
        <AddSolicitationTypeDialogComponent
          v-if="openDialogAddSolicitation"
          @closeDialog="closeDialogAddSolicitation"
          @saveDialog="saveAddSolicitation($event)"
          :loadingButtonDialog="loadingButtonDialog"
          :providerId="providerId"
        />
      </div>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import AddSolicitationTypeDialogComponent from "../../components/provider/AddSolicitationTypeDialogComponent.vue";
import SolicitationTypeTagsComponent from "../../components/solicitationType/SolicitationTypeTagsComponent.vue";
export default {
  components: {SolicitationTypeTagsComponent, AddSolicitationTypeDialogComponent},
  data() {
    return {
      provider_form: {
        name: "",
        email: "",
        operadora_id_join: "",
        tags: []
      },
      loading: false,
      creating: false,
      loadingButtonDialog: false,
      openDialogAddSolicitation: false,
      providerId: "",
      valid: true,
      emailRules: [
        v => !!v || "E-mail obrigatório",
        v =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail deve ser válido"
      ],
      nameRules: [v => !!v || "Nome obrigatório"]
    };
  },
  async mounted() {
    this.loading = true;
    await this.loadEmailConfig().catch(error => {
      this.$swal("Oops...", error.message, "error");
    });
    await this.loadOperadorasJoin().catch(error => {
      this.$swal("Oops...", error.message, "error");
    });
    this.loading = false;
  },
  computed: {
    ...mapState("provider", ["provider_save","operadoras_join"]),
    ...mapState("configurationCompany", ["email_config"])
  },
  methods: {
    ...mapActions("provider", ["saveProvider","saveSolicitationTypeProvider","loadOperadorasJoin"]),
    ...mapActions("configurationCompany", ["loadEmailConfig"]),

    saveTag(value) {
      this.provider_form.tags = value;
    },

    async saveProviderItem() {
      if (this.$refs.form.validate()) {
        this.creating = true;
        const params = this.provider_form;
        await this.saveProvider(params).catch(error => {
          this.$swal("Oops...", error.message, "error");
        });
        if (this.provider_save.success) {
          this.$swal({
            icon: "success",
            title: this.provider_save.message,
            showCancelButton: true,
            confirmButtonText: "Continuar cadastrando",
            cancelButtonText: "Selecionar Tipos de Solicitação",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#445E93",
            showLoaderOnConfirm: true
          }).then(result => {
            if (!result.value) {
              this.providerId = this.provider_save.data.id;
              this.openDialogAddSolicitation = true;
            } else {
              this.provider_form = {};
              this.$refs.form.resetValidation();
            }
          });
        } else {
          this.$swal("Oops ...", this.provider_save.message, "warning");
        }
        this.creating = false;
      }
    },
    async saveAddSolicitation(params) {
      await this.saveSolicitationTypeProvider(params).catch(error => {
        this.$swal("Oops...", error.message, "error");
      });

      await this.$swal("Tipos de Solicitações cadastradas com sucesso!", "", "success");
      this.closeDialogAddSolicitation();
      this.loadingButtonDialog = false;
    },
    closeDialogAddSolicitation() {
      this.openDialogAddSolicitation = false;
      this.$router.push({
        name: "provider-list"
      });
    }
  }
};
</script>
